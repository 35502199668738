<template>
  <el-dialog
      title="新增"
      v-model="performanceadd"
      width="50%"
      height="200px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div">
  <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">

	<el-row>
		<el-form-item label="" >
		  <el-button   @click="info()">添加报修人</el-button>
		  <el-button   @click="infoManual()">手动添写报修人</el-button>
		</el-form-item>
		
		<el-form-item label="报修人" prop="users_name" :rules="[
		        { required: true, message: '请添加', trigger: 'blur' },
		      ]"
		>
		  <el-input :readonly="setManual"   v-model="ruleForm.users_name"></el-input>
		</el-form-item>
		<el-form-item label="联系方式" prop="users_phone"  :rules="[
		        { required: true, message: '请添加', trigger: 'blur' },
		      ]"
		>
		  <el-input  :readonly="setManual" v-model="ruleForm.users_phone"></el-input>
		</el-form-item>
	</el-row>
  
	<el-row>
		<el-form-item label="报修人类型" prop="optionpar" :rules="[
		        { required: true, message: '请选择', trigger: 'blur' },
		      ]"
		  >
		    <el-select  :disabled="setManual"  v-model="ruleForm.optionpar"  placeholder="请选择" >
		      <el-option  v-for="data in options" :key="data.value" :label="data.label" :value="data.value">
		      </el-option>
		    </el-select>
		  </el-form-item>
		<el-form-item label="所属项目" prop="article" :rules="[
		    { required: true, message: '请输入', trigger: 'blur' },
		  ]"
		>

		  <el-select @change="plotIdChange" v-model="ruleForm.article"  placeholder="请选择" style="width:100%">
		    <el-option v-for="data in ruleForm.optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id">
		    </el-option>
		  </el-select>
		</el-form-item>
		
		<el-form-item label="报修分类" prop="father_name" :rules="[
		      { required: true, message: '请输入', trigger: 'blur' },
		    ]"
		>
		  <el-select   v-model="ruleForm.father_name"  placeholder="请选择" style="width:100%">
		    <el-option  v-for="data in father_names" :key="data.father_id" :label="data.classname" :value="data.father_id">
		    </el-option>
		  </el-select>
		</el-form-item>
	</el-row>

   
<el-row>
    <el-form-item label="工单类型" prop="order_type" :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
    >
      <el-select @change="amountchang"   v-model="ruleForm.order_type"  placeholder="请选择" style="width:100%">
        <el-option  v-for="data in order_types" :key="data.value" :label="data.label" :value="data.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="报修模式" prop="mode" :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
    >
      <el-select @change="amountchang"   v-model="ruleForm.mode"  placeholder="请选择" style="width:100%">
        <el-option  v-for="data in statuss" :key="data.value" :label="data.label" :value="data.value">
        </el-option>
      </el-select>
    </el-form-item>

      <el-form-item label="报修金额" prop="money"    :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
      >
        <el-input   v-model="ruleForm.money" :readonly="moneytype"></el-input>
      </el-form-item>
</el-row>
<el-row>
      <el-form-item label="报修地点-楼" prop="floor" :rules="[
            { required: true, message: '请输入', trigger: 'blur' },
          ]"
      >
        <el-input   v-model="ruleForm.floor"></el-input>
      </el-form-item>
	  
	  <el-form-item label="报修地点-层" prop="layer" :rules="[
	        { required: true, message: '请输入', trigger: 'blur' },
	      ]"
	  >
	    <el-input   v-model="ruleForm.layer"></el-input>
	  </el-form-item>
	  
	  <el-form-item label="报修区域" prop="region" :rules="[
	        { required: true, message: '请输入', trigger: 'blur' },
	      ]"
	  >
	    <el-input   v-model="ruleForm.region"></el-input>
	  </el-form-item>
	</el-row>  

      <el-form-item label="详细信息" prop="content" >
        <el-input  type="textarea" v-model="ruleForm.content"></el-input>
      </el-form-item>

    <el-form-item label="图片地址" prop="content"  >
      <el-input  type="textarea" :readonly="true" v-model="ruleForm.picture"></el-input>
    </el-form-item>


    <el-form-item label="图片" prop="content" >
      <div>
        <img id="img" border="11"  v-for="item in uploadFiles" v-bind:key="item" style="  margin: 10px;  padding: 3px; width:100px;height:100px;border:solid 1px #6a7990; border-radius:10%" :src="item.url" @click="delid(item)"/>
      </div>
    </el-form-item>
    <el-form-item label="" prop="content" >
        <input id="filePhoto" class="select" ref="imgInput" type="file" accept="image/*" @change="uploadImg($event)">
    </el-form-item >

    </el-form>


    <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i class="iImg"></i>确定</button>
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>
  <rep_repairs_addM @closerepairsaddM="closerepairsaddM"  v-if="repairsaddMDialogVisible"></rep_repairs_addM>

</template>

<script>
import rep_repairs_addM from './rep_repairs_addM.vue'

export default {
  created () {
    this.performanceadd = true;
    this.clearinfo();
    this.getplotname();	
    this.getclassname();	
  },
  data(){
    let self=this;
    return{
	  getPlot_id:false,
      dialog_visible:false,
      repairsaddMDialogVisible:false,  //经理权限界面的标识
      dataNum:0,
      user_id:'',
      position:'top',
      moneytype:false,
	  setManual:true,
      ruleForm:{
		article:'',
		articles:'',
		plot_id:'',
        id:this.$parent.id,
        optionsype:[],
        father_names:'',
        picture:'',       //图片存放的变量
		money:0,
		mode:'2',
		order_type:'1',
		optionpar:''
      },
		options:[                 //定以下拉框的值
        {
          value:'0',
          label:'普通人员',
        },
        {
          value:'1',
          label:'服务人员',
        },
        {
          value:'2',
          label:'维修人员',
        },
        {
          value:'3',
          label:'工程经理',
        },
        {
          value:'4',
          label:'项目管理人员',
        }

      ],
      uploadFiles:[

      ],
      statuss:[
        {
          value:'1',
          label:'有偿',
        },
        {
          value:'2',
          label:'无偿',
        },
      ],
      order_types:[
        {
          value:'1',
          label:'等待抢单',
        },
		{
		  value:'4',
		  label:'其他单位',
		}
      ],
    }},
	
  methods: {
    amountchang:function(val){
      if(val==2){
        this.moneytype=true;
        this.ruleForm.money=0;
      }else{
		  this.moneytype=false;
		  this.ruleForm.money=0;
	  }
    },
    closeDialog: function(){
      this.clearinfo();
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    commit:function(ruleForm){
		
      this.$refs[ruleForm].validate((valid) => {

        if (valid) {
          var self = this;
          var data = {};
          data.cmd='insertRepairsInfo';
          data.father_id=this.ruleForm.father_name;
         
		  if(this.getPlot_id){
			   data.plot_id=this.ruleForm.article;
		  }else{
			   data.plot_id=this.ruleForm.plot_id;
		  }

		  var plot_idS = "";
		  for(var i=0;i<this.ruleForm.articles.length;i++){
			  plot_idS += this.ruleForm.articles[i]+",";
		  }
		  if(plot_idS != ""){
			   data.plotIds = plot_idS.substring(0,plot_idS.length-1);
		  }
		 
          data.mode=this.ruleForm.mode;
          data.money=this.ruleForm.money;
         
		  data.floor=this.ruleForm.floor;
		  data.layer=this.ruleForm.layer;
		  data.region=this.ruleForm.region;
		  
          data.content=this.ruleForm.content;
          data.user_id=this.user_id;
          data.order_type=this.ruleForm.order_type;
		  data.users_name = this.ruleForm.users_name;
		  data.users_phone = this.ruleForm.users_phone;
		  data.ismanager = this.ruleForm.optionpar;
		  data.is_substitute = 1;
          data.picture=this.ruleForm.picture;
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            self.$message("新增成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },



    //查询所有项目名称和ID的方法
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
		  this.ruleForm.optionsype = [];
		  for(var i=0;i<datas.datas.items.length;i++){
			  var obj = {};
			  obj.plot_id = datas.datas.items[i].plot_id;
		  	  obj.plot_name = datas.datas.items[i].plot_name + "";
			  this.ruleForm.optionsype.push(obj);
		  }		
		 if(self.ruleForm.optionsype.length == 1){
			self.ruleForm.article = self.ruleForm.optionsype[0].plot_id;
		 } 
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
    //查看所有分类名称的方法
    getclassname:function(){
		
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getClassName";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.father_names=datas.datas.items;
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
	plotIdChange:function(val){
		this.getPlot_id = true;
    },
    closerepairsaddM:function(data){
      this.ruleForm.users_name=data.user_name;
      this.ruleForm.users_phone=data.mobile;
      this.user_id=data.id;
	  this.ruleForm.optionpar = data.manage;
	  for(var i=0;i<this.ruleForm.optionsype.length;i++){
		  if(data.plot_id == this.ruleForm.optionsype[i].plot_id){
			  this.ruleForm.article = data.plot_id ; //data.plot_name;
			  this.ruleForm.plot_id = data.plot_id;
			  this.getPlot_id = false;
			  break;
		  }
	  }
	
      this.repairsaddMDialogVisible  = false;
    },
	infoManual:function(){
		 this.user_id = 0;
		 this.setManual = false
		 
		 this.ruleForm.optionpar = '0';
		 
	},
    /*跳转编辑页面的方法*/
    info: function() {
		 this.setManual = true;
      this.repairsaddMDialogVisible = true;
    },
    clearinfo:function(){
      this.description = "";
    },
    uploadContract: function(e) {
      this.file = e.target.files[0];
      var self = this;
      var req = {};
      req.cmd = "repairs_uploadFile";

      this.$sknet.uploadFile(this.$skconf("iot.surl"), req,["upload_contract_file"], function(res) {

      }, null, this);
    },
    //删除图片的方法
    delid:function(item){

      for(let i=0;this.uploadFiles.length>i;i++){
        if(this.uploadFiles[i].url===item.url){
          this.uploadFiles.splice(i, 1);

        }
      }
     if(this.uploadFiles.length==0){
       this.ruleForm.picture=[];
     }
      for(let i=0;this.uploadFiles.length>i;i++){
        if(i==0){
          this.ruleForm.picture=  this.uploadFiles[i].url;
        }
        else{
          this.ruleForm.picture= this.ruleForm.picture+','+ this.uploadFiles[i].url;
        }
      }
    },

    /*转换程base64上传后台图片的方法*/
    uploadImg:function(e){
      if(this.uploadFiles.length>5){
        return;
      }
      var filename=document.getElementById('filePhoto').files[0].name
      var req={};
      req.cmd='uploadPictures';

      let file=e.target.files[0];

      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
       //为img对应的集合赋值
       let url= e.target.result;


       url=url.split(',');
       req.fileUrl=url[1];
       req.filename=filename;

       this.upload(req);
      };
    },

    upload: function(req){
      let self=this;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(data){

        var fileUrloss='https://pms.xhjr.tech/oss/file/'+data.datas.items.fileUrl;
        var url;

      if(self.ruleForm.picture==null||self.ruleForm.picture==''){
        url=fileUrloss;
        var car={ url:url}
        this.uploadFiles.push(car)

         self.ruleForm.picture=fileUrloss;
      }
      else{
        url=fileUrloss;
        var car={ url:url}
        this.uploadFiles.push(car)

        self.ruleForm.picture=self.ruleForm.picture+','+fileUrloss;
      }

      }, function(data) {

        self.$message(data.reason);
      }, self);
    }

  },
  mounted(){	
	
  },
  components: {
    rep_repairs_addM,
  },

}
</script>

<style scoped>

.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}

</style>