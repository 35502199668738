<template>
  <el-dialog
      title="指派"
      v-model="perforassign"
      width="50%"
      height="100px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div" style="padding-right:22px">
      <el-form ref="searchForm"   label-width="240" :inline="true">
        <el-form-item label="综合查询:" prop="name"><el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
        <el-form-item><el-button type="primary" @click="handleClickQuery()">搜索</el-button></el-form-item>
      </el-form>
      <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
        <el-table :data="dataList" border  ref="multipleTable">
          <el-table-column prop="id" label="ID" ></el-table-column>
          <el-table-column prop="user_name" label="用户名" ></el-table-column>
          <el-table-column prop="user_imgs" label="用户头像" >
            <template width="60" v-slot="colum" style="width:55px" >
              <el-image style="width:50px;height:50px;border:none; border-radius:70%" :src="colum.row.user_imgs" />
            </template>
          </el-table-column>
          <el-table-column prop="real_name" label="真实姓名" ></el-table-column>
          <el-table-column prop="is_busy" label="用户状态" :formatter="forstate"></el-table-column>
          <el-table-column fixed="right" label="操作" style="width:0px" >
            <template v-slot="scope">
              <el-button  icon="el-icon-edit"    @click="Modify(scope.row)" type="success" size="small">指派工单</el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-form>

      <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="closeDialog"><i class="iImg"></i>确定</button>
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

export default {
  created () {
    this.perforassign = true;
    this.handleClickQuery();
    this.clearinfo();
  },


  data() {

    let self = this;

    return {
      dataList: [],  //表格数据集
      tableDataName:'',
    }
  },

  methods: {

    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchMembersByOrder";
      req.manage=1;
      req.order_id=this.$parent.orderid;  //报修id
      req.synthesize_params=this.tableDataName;
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.dataList=res.datas.items;
      }, null, this);
    },
    //指派工单
    Modify: function(item) {
    if(item.is_busy !=1){
       this.$message({
         message: '该记录已指派',
         type: 'warning'
       });
     }
    else{
      var req = {};
      req.cmd = "orderPersonnel";
      req.repair_user=item.id;
      req.id=this.$parent.orderid;  //报修id
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.handleClickQuery();
      }, null, this);
    }
   },

   //用户状态
   forstate:function(row,colum){

     const state={
       1:'空闲',
       2:'已指派',
     }
     return  state[row.is_busy];
   },
   closeDialog: function(){

     this.clearinfo();
     this.$emit('closerepairsAssign'); //通知父组件改变。
   },
   clearinfo:function(){
     this.description = "";
   },

 },
 mounted(){

 },
 components: {

 },
}
</script>

<style scoped>

.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}

</style>