<template>
  <el-dialog
      title="指派"
      v-model="perforassign"
      width="50%"
      height="100px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div" style="padding-right:22px">
      <el-form ref="searchForm"   label-width="240" :inline="true">
        <el-form-item label="当前报修状态:" prop="status" >
          <el-select v-model="article" placeholder="请选择" style="width:240px"     clearable >
            <el-option label="未完成" value="1"></el-option>
            <el-option label="待评价" value="2"></el-option>
            <el-option label="待评级" value="3"></el-option>
            <el-option label="已完成" value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="当前工单类型:" prop="status" >
          <el-select v-model="article" placeholder="请选择" style="width:240px"     clearable >
            <el-option label="等待抢单" value="1"></el-option>
            <el-option label="前台抢单" value="2"></el-option>
            <el-option label="后台分配" value="3"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">
        <el-table :data="dataList" border  ref="multipleTable">
          <el-table-column prop="id" label="ID" ></el-table-column>
          <el-table-column prop="user_name" label="用户名" ></el-table-column>
          <el-table-column fixed="right" label="操作" style="width:50px" >
            <template v-slot="scope">
              <el-button  icon="el-icon-edit" @click="Deleteinfo(scope.row)" type="success" size="small">删除</el-button>
            </template>
          </el-table-column>
        </el-table>

      </el-form>

      <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit()"><i class="iImg"></i>确定</button>
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>

</template>

<script>

export default {
  created () {
    this.perforassign = true;
    this.handleClickQuery();
    this.clearinfo();

  },


  data() {

    let self = this;

    return {
      dataList: [],  //表格数据集
      tableDataName:'',
      article:'',
      optionsype:[                 //定以下拉框的值
        {
          value:'0',
          label:'普通人员',
        },
        {
          value:'1',
          label:'服务人员',
        },
        {
          value:'2',
          label:'维修人员',
        },
        {
          value:'3',
          label:'工程经理',
        },
		{
		  value:'4',
		  label:'项目管理人员',
		}

      ],
    }
  },

  methods: {

    commit:function(){

          var self = this;
          var data = {};
          data.cmd = "updateRepairsStatusById";
          data.order_id=this.$parent.id;  //报修id
          data.status=this.article;

          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){

            self.$message("修改成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);
    },

    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchNameByOrderId";
      req.order_id=this.$parent.id;  //报修id

      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.article=this.$parent.status
        this.dataList=res.datas.items;
      }, null, this);
    },

    /*删除的方法*/
    Deleteinfo:function(item){
      this.$confirm('是否确认删除。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del(item);
      }).catch(() => {});
    },
    //删除的方法
    del: function(item) {
        var req = {};
        req.cmd = "delOrderAndMemberByid";
        req.member_id=item.member_id;
        req.order_id=item.order_id;  //报修id

        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.handleClickQuery();
        }, null, this);

    },

    closeDialog: function(){

      this.clearinfo();
      this.$emit('closerepairsBaja'); //通知父组件改变。
    },
    clearinfo:function(){
      this.description = "";
    },

  },
  mounted(){

  },
  components: {

  },
}
</script>

<style scoped>

.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}

</style>